import { default as _91_46_46_46slug_93kpliPsi0c5Meta } from "/build/pages/[...slug].vue?macro=true";
import { default as _91id_93iVeoYDzJGWMeta } from "/build/pages/[[lang]]/adoption/[id].vue?macro=true";
import { default as _91id_93gAhhrPX31rMeta } from "/build/pages/[[lang]]/booking/cancellation/[id].vue?macro=true";
import { default as step_45additional_45informationitTx8MgTJHMeta } from "/build/pages/[[lang]]/booking/step-additional-information.vue?macro=true";
import { default as step_45birthday_45petXo2hzAMvExMeta } from "/build/pages/[[lang]]/booking/step-birthday-pet.vue?macro=true";
import { default as step_45castration_45petffAsVc8O9SMeta } from "/build/pages/[[lang]]/booking/step-castration-pet.vue?macro=true";
import { default as step_45coat_45lengthQrUHXVww66Meta } from "/build/pages/[[lang]]/booking/step-coat-length.vue?macro=true";
import { default as step_45confirmationxOZTBfMTGUMeta } from "/build/pages/[[lang]]/booking/step-confirmation.vue?macro=true";
import { default as step_45date_45timeIOWXOCgf7QMeta } from "/build/pages/[[lang]]/booking/step-date-time.vue?macro=true";
import { default as step_45dateNlycFgDMfvMeta } from "/build/pages/[[lang]]/booking/step-date.vue?macro=true";
import { default as step_45gender_45pet53Cv4mKk9TMeta } from "/build/pages/[[lang]]/booking/step-gender-pet.vue?macro=true";
import { default as step_45lifephase_45pethxXHvuXZIrMeta } from "/build/pages/[[lang]]/booking/step-lifephase-pet.vue?macro=true";
import { default as step_45loginOmdBig7nbsMeta } from "/build/pages/[[lang]]/booking/step-login.vue?macro=true";
import { default as step_45matted_45petELbFfUSzviMeta } from "/build/pages/[[lang]]/booking/step-matted-pet.vue?macro=true";
import { default as step_45name_45petaEeu7Vauf6Meta } from "/build/pages/[[lang]]/booking/step-name-pet.vue?macro=true";
import { default as step_45package_45selectionOOwSyKN584Meta } from "/build/pages/[[lang]]/booking/step-package-selection.vue?macro=true";
import { default as step_45personal_45dataVkIVOaLbxDMeta } from "/build/pages/[[lang]]/booking/step-personal-data.vue?macro=true";
import { default as step_45race_45petzBjtfJK45mMeta } from "/build/pages/[[lang]]/booking/step-race-pet.vue?macro=true";
import { default as step_45register9XwI3lXuBtMeta } from "/build/pages/[[lang]]/booking/step-register.vue?macro=true";
import { default as step_45select_45pet3Zl3RS2nnYMeta } from "/build/pages/[[lang]]/booking/step-select-pet.vue?macro=true";
import { default as step_45size_45petcuUpeQ4VZKMeta } from "/build/pages/[[lang]]/booking/step-size-pet.vue?macro=true";
import { default as step_45startDDm4mmTmleMeta } from "/build/pages/[[lang]]/booking/step-start.vue?macro=true";
import { default as step_45summary88n0xuiyMvMeta } from "/build/pages/[[lang]]/booking/step-summary.vue?macro=true";
import { default as step_45timeagc9qOY3NFMeta } from "/build/pages/[[lang]]/booking/step-time.vue?macro=true";
import { default as step_45visit_45petlqvnqsfVqOMeta } from "/build/pages/[[lang]]/booking/step-visit-pet.vue?macro=true";
import { default as indexHyIvumMkXkMeta } from "/build/pages/[[lang]]/cart/index.vue?macro=true";
import { default as payback_45authdplWFuUOsOMeta } from "/build/pages/[[lang]]/cart/payback-auth.vue?macro=true";
import { default as payback_45errorAV9OcFbgLyMeta } from "/build/pages/[[lang]]/cart/payback-error.vue?macro=true";
import { default as payback_45successJouKU2Ir1uMeta } from "/build/pages/[[lang]]/cart/payback-success.vue?macro=true";
import { default as indexTJ87MYtFbjMeta } from "/build/pages/[[lang]]/checkout/address/index.vue?macro=true";
import { default as indexOXSa3K0PImMeta } from "/build/pages/[[lang]]/checkout/confirmation/index.vue?macro=true";
import { default as indexfX0HKtMmgsMeta } from "/build/pages/[[lang]]/checkout/delivery/index.vue?macro=true";
import { default as indexjjAIswsZu0Meta } from "/build/pages/[[lang]]/checkout/index.vue?macro=true";
import { default as indexiHWriXZ0GSMeta } from "/build/pages/[[lang]]/checkout/native-3ds/index.vue?macro=true";
import { default as payment_45redirect_45errorBKmvBrilH4Meta } from "/build/pages/[[lang]]/checkout/payment-redirect-error.vue?macro=true";
import { default as payment_45redirect_45successNvk0N1qwOxMeta } from "/build/pages/[[lang]]/checkout/payment-redirect-success.vue?macro=true";
import { default as indexpoVm5EDZn8Meta } from "/build/pages/[[lang]]/checkout/payment/blik/index.vue?macro=true";
import { default as indexYUV0aJPwC6Meta } from "/build/pages/[[lang]]/checkout/payment/index.vue?macro=true";
import { default as indexlMGmS8HmisMeta } from "/build/pages/[[lang]]/checkout/payment/payconiq/index.vue?macro=true";
import { default as indexFWDB8PJFY9Meta } from "/build/pages/[[lang]]/checkout/start/index.vue?macro=true";
import { default as indexLGZsz60KbAMeta } from "/build/pages/[[lang]]/checkout/summary/index.vue?macro=true";
import { default as errors_45srr_45triggerZ9TVJPTRKGMeta } from "/build/pages/[[lang]]/dev/errors-srr-trigger.vue?macro=true";
import { default as errorsxhPXfqTiRaMeta } from "/build/pages/[[lang]]/dev/errors.vue?macro=true";
import { default as bindings59EmOyxReEMeta } from "/build/pages/[[lang]]/dev/forms/bindings.vue?macro=true";
import { default as fieldsDYVaXJT4S0Meta } from "/build/pages/[[lang]]/dev/forms/fields.vue?macro=true";
import { default as indexpiAgDQeGR9Meta } from "/build/pages/[[lang]]/dev/forms/index.vue?macro=true";
import { default as load_45scriptdFCBbK6EaHMeta } from "/build/pages/[[lang]]/dev/load-script.vue?macro=true";
import { default as indexGeKLNkvPfuMeta } from "/build/pages/[[lang]]/login/index.vue?macro=true";
import { default as indexlwEbJtCW3TMeta } from "/build/pages/[[lang]]/logout/index.vue?macro=true";
import { default as addressesakWgKQ3uVLMeta } from "/build/pages/[[lang]]/my-account/addresses.vue?macro=true";
import { default as buy_45again7GcZRt0jPhMeta } from "/build/pages/[[lang]]/my-account/buy-again.vue?macro=true";
import { default as dataUZJF7QDi9gMeta } from "/build/pages/[[lang]]/my-account/data.vue?macro=true";
import { default as friendsYTx9HPnHz6Meta } from "/build/pages/[[lang]]/my-account/friends.vue?macro=true";
import { default as indextGSrBiUrcgMeta } from "/build/pages/[[lang]]/my-account/index.vue?macro=true";
import { default as newsletterIeXWzYxdQsMeta } from "/build/pages/[[lang]]/my-account/newsletter.vue?macro=true";
import { default as _91id_933yUs7BI46dMeta } from "/build/pages/[[lang]]/my-account/orders/help/[id].vue?macro=true";
import { default as confirmationeJ0Qq31HCjMeta } from "/build/pages/[[lang]]/my-account/orders/help/confirmation.vue?macro=true";
import { default as indexAeIJD5NpaAMeta } from "/build/pages/[[lang]]/my-account/orders/index.vue?macro=true";
import { default as index7XiYwTym7oMeta } from "/build/pages/[[lang]]/my-account/orders/market/index.vue?macro=true";
import { default as paybackdoxb492qlsMeta } from "/build/pages/[[lang]]/my-account/payback.vue?macro=true";
import { default as paymentGf0KkZqb7mMeta } from "/build/pages/[[lang]]/my-account/payment.vue?macro=true";
import { default as petsawHZmg9lOxMeta } from "/build/pages/[[lang]]/my-account/pets.vue?macro=true";
import { default as recommendations9IoZoMlAHaMeta } from "/build/pages/[[lang]]/my-account/recommendations.vue?macro=true";
import { default as saved_45postsMc1r1sy4VQMeta } from "/build/pages/[[lang]]/my-account/saved-posts.vue?macro=true";
import { default as wishlisto5JqIA8tcdMeta } from "/build/pages/[[lang]]/my-account/wishlist.vue?macro=true";
import { default as _91id_93AgNhpjm4cnMeta } from "/build/pages/[[lang]]/newsletter/campaign/unsubscribe/[id].vue?macro=true";
import { default as _91id_93DHgtQXzYaTMeta } from "/build/pages/[[lang]]/newsletter/confirmation/[id].vue?macro=true";
import { default as _91_91id_93_93iXm2uSCpFaMeta } from "/build/pages/[[lang]]/newsletter/edit-preferences/[[id]].vue?macro=true";
import { default as _91action_93vN24rlw6RqMeta } from "/build/pages/[[lang]]/newsletter/subscription/[action].vue?macro=true";
import { default as _91id_93la8xB8ypC0Meta } from "/build/pages/[[lang]]/newsletter/unsubscribe/[id].vue?macro=true";
import { default as _91product_93xjL47qSQU8Meta } from "/build/pages/[[lang]]/p/[product].vue?macro=true";
import { default as index0Nqw4wDwnzMeta } from "/build/pages/[[lang]]/password/request/index.vue?macro=true";
import { default as indexQy1Nnn7x6pMeta } from "/build/pages/[[lang]]/password/request/thank-you/index.vue?macro=true";
import { default as indexAuQJdqZEagMeta } from "/build/pages/[[lang]]/password/reset/expired/index.vue?macro=true";
import { default as indexl1tdvlQY1GMeta } from "/build/pages/[[lang]]/password/reset/password/index.vue?macro=true";
import { default as indexEZkfQuuGVyMeta } from "/build/pages/[[lang]]/register/index.vue?macro=true";
import { default as indexslofJdndAGMeta } from "/build/pages/[[lang]]/search/index.vue?macro=true";
import { default as indexrYXIzt6F0pMeta } from "/build/pages/[[lang]]/store-editor/dashboard/index.vue?macro=true";
import { default as indexjP1vKzeyeCMeta } from "/build/pages/[[lang]]/store-editor/data/index.vue?macro=true";
import { default as _91id_93OpbL7uawIMMeta } from "/build/pages/[[lang]]/store-editor/detail/[id].vue?macro=true";
import { default as indexK4PE37mgBlMeta } from "/build/pages/[[lang]]/store-editor/index.vue?macro=true";
import { default as indexCA9vWx9WNCMeta } from "/build/pages/[[lang]]/storefinder/index.vue?macro=true";
import { default as _91slug_93VFuveun8E3Meta } from "/build/pages/[[lang]]/stores/[slug].vue?macro=true";
import { default as indexZT2vdvJp7wMeta } from "/build/pages/[[lang]]/subscription/newsletter/preferences/index.vue?macro=true";
import { default as indexDEp5ZTN6JQMeta } from "/build/pages/[[lang]]/subscription/newsletter/unsubscribe/index.vue?macro=true";
export default [
  {
    name: "catchall",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93kpliPsi0c5Meta || {},
    component: () => import("/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "adoption-detail",
    path: "/:lang?/adoption/:id()",
    component: () => import("/build/pages/[[lang]]/adoption/[id].vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-cancel",
    path: "/:lang?/booking/cancellation/:id()",
    meta: _91id_93gAhhrPX31rMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/cancellation/[id].vue").then(m => m.default || m)
  },
  {
    name: "step-additional-information",
    path: "/:lang?/booking/step-additional-information",
    meta: step_45additional_45informationitTx8MgTJHMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-additional-information.vue").then(m => m.default || m)
  },
  {
    name: "step-birthday-pet",
    path: "/:lang?/booking/step-birthday-pet",
    meta: step_45birthday_45petXo2hzAMvExMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-birthday-pet.vue").then(m => m.default || m)
  },
  {
    name: "step-castration-pet",
    path: "/:lang?/booking/step-castration-pet",
    meta: step_45castration_45petffAsVc8O9SMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-castration-pet.vue").then(m => m.default || m)
  },
  {
    name: "step-coat-length",
    path: "/:lang?/booking/step-coat-length",
    meta: step_45coat_45lengthQrUHXVww66Meta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-coat-length.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-confirmation",
    path: "/:lang?/booking/step-confirmation",
    meta: step_45confirmationxOZTBfMTGUMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-date-time",
    path: "/:lang?/booking/step-date-time",
    meta: step_45date_45timeIOWXOCgf7QMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-date-time.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-date",
    path: "/:lang?/booking/step-date",
    meta: step_45dateNlycFgDMfvMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-date.vue").then(m => m.default || m)
  },
  {
    name: "step-gender-pet",
    path: "/:lang?/booking/step-gender-pet",
    meta: step_45gender_45pet53Cv4mKk9TMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-gender-pet.vue").then(m => m.default || m)
  },
  {
    name: "step-lifephase-pet",
    path: "/:lang?/booking/step-lifephase-pet",
    meta: step_45lifephase_45pethxXHvuXZIrMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-lifephase-pet.vue").then(m => m.default || m)
  },
  {
    name: "step-login",
    path: "/:lang?/booking/step-login",
    meta: step_45loginOmdBig7nbsMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-login.vue").then(m => m.default || m)
  },
  {
    name: "step-matted-pet",
    path: "/:lang?/booking/step-matted-pet",
    meta: step_45matted_45petELbFfUSzviMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-matted-pet.vue").then(m => m.default || m)
  },
  {
    name: "step-name-pet",
    path: "/:lang?/booking/step-name-pet",
    meta: step_45name_45petaEeu7Vauf6Meta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-name-pet.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-package-selection",
    path: "/:lang?/booking/step-package-selection",
    meta: step_45package_45selectionOOwSyKN584Meta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-package-selection.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-personal-data",
    path: "/:lang?/booking/step-personal-data",
    meta: step_45personal_45dataVkIVOaLbxDMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-personal-data.vue").then(m => m.default || m)
  },
  {
    name: "step-race-pet",
    path: "/:lang?/booking/step-race-pet",
    meta: step_45race_45petzBjtfJK45mMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-race-pet.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-register",
    path: "/:lang?/booking/step-register",
    meta: step_45register9XwI3lXuBtMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-register.vue").then(m => m.default || m)
  },
  {
    name: "step-select-pet",
    path: "/:lang?/booking/step-select-pet",
    meta: step_45select_45pet3Zl3RS2nnYMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-select-pet.vue").then(m => m.default || m)
  },
  {
    name: "step-size-pet",
    path: "/:lang?/booking/step-size-pet",
    meta: step_45size_45petcuUpeQ4VZKMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-size-pet.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-start",
    path: "/:lang?/booking/step-start",
    meta: step_45startDDm4mmTmleMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-start.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-summary",
    path: "/:lang?/booking/step-summary",
    meta: step_45summary88n0xuiyMvMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-summary.vue").then(m => m.default || m)
  },
  {
    name: "salon-booking-step-time",
    path: "/:lang?/booking/step-time",
    meta: step_45timeagc9qOY3NFMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-time.vue").then(m => m.default || m)
  },
  {
    name: "step-visit-pet",
    path: "/:lang?/booking/step-visit-pet",
    meta: step_45visit_45petlqvnqsfVqOMeta || {},
    component: () => import("/build/pages/[[lang]]/booking/step-visit-pet.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/:lang?/cart",
    component: () => import("/build/pages/[[lang]]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-cart-payback-auth",
    path: "/:lang?/cart/payback-auth",
    meta: payback_45authdplWFuUOsOMeta || {},
    component: () => import("/build/pages/[[lang]]/cart/payback-auth.vue").then(m => m.default || m)
  },
  {
    name: "cart-payback-error",
    path: "/:lang?/cart/payback-error",
    meta: payback_45errorAV9OcFbgLyMeta || {},
    component: () => import("/build/pages/[[lang]]/cart/payback-error.vue").then(m => m.default || m)
  },
  {
    name: "cart-payback-success",
    path: "/:lang?/cart/payback-success",
    meta: payback_45successJouKU2Ir1uMeta || {},
    component: () => import("/build/pages/[[lang]]/cart/payback-success.vue").then(m => m.default || m)
  },
  {
    name: "checkout-address",
    path: "/:lang?/checkout/address",
    meta: indexTJ87MYtFbjMeta || {},
    component: () => import("/build/pages/[[lang]]/checkout/address/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation",
    path: "/:lang?/checkout/confirmation",
    meta: indexOXSa3K0PImMeta || {},
    component: () => import("/build/pages/[[lang]]/checkout/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery",
    path: "/:lang?/checkout/delivery",
    meta: indexfX0HKtMmgsMeta || {},
    component: () => import("/build/pages/[[lang]]/checkout/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/:lang?/checkout",
    meta: indexjjAIswsZu0Meta || {},
    component: () => import("/build/pages/[[lang]]/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-adyen-3ds-challenge",
    path: "/:lang?/checkout/native-3ds",
    meta: indexiHWriXZ0GSMeta || {},
    component: () => import("/build/pages/[[lang]]/checkout/native-3ds/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment-redirect-error",
    path: "/:lang?/checkout/payment-redirect-error",
    meta: payment_45redirect_45errorBKmvBrilH4Meta || {},
    component: () => import("/build/pages/[[lang]]/checkout/payment-redirect-error.vue").then(m => m.default || m)
  },
  {
    name: "lang-checkout-payment-redirect-success",
    path: "/:lang?/checkout/payment-redirect-success",
    component: () => import("/build/pages/[[lang]]/checkout/payment-redirect-success.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment-blik",
    path: "/:lang?/checkout/payment/blik",
    meta: indexpoVm5EDZn8Meta || {},
    component: () => import("/build/pages/[[lang]]/checkout/payment/blik/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment",
    path: "/:lang?/checkout/payment",
    meta: indexYUV0aJPwC6Meta || {},
    component: () => import("/build/pages/[[lang]]/checkout/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment-payconiq",
    path: "/:lang?/checkout/payment/payconiq",
    meta: indexlMGmS8HmisMeta || {},
    component: () => import("/build/pages/[[lang]]/checkout/payment/payconiq/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-start",
    path: "/:lang?/checkout/start",
    meta: indexFWDB8PJFY9Meta || {},
    component: () => import("/build/pages/[[lang]]/checkout/start/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-summary",
    path: "/:lang?/checkout/summary",
    meta: indexLGZsz60KbAMeta || {},
    component: () => import("/build/pages/[[lang]]/checkout/summary/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-dev-errors-srr-trigger",
    path: "/:lang?/dev/errors-srr-trigger",
    meta: errors_45srr_45triggerZ9TVJPTRKGMeta || {},
    component: () => import("/build/pages/[[lang]]/dev/errors-srr-trigger.vue").then(m => m.default || m)
  },
  {
    name: "lang-dev-errors",
    path: "/:lang?/dev/errors",
    meta: errorsxhPXfqTiRaMeta || {},
    component: () => import("/build/pages/[[lang]]/dev/errors.vue").then(m => m.default || m)
  },
  {
    name: "lang-dev-forms-bindings",
    path: "/:lang?/dev/forms/bindings",
    meta: bindings59EmOyxReEMeta || {},
    component: () => import("/build/pages/[[lang]]/dev/forms/bindings.vue").then(m => m.default || m)
  },
  {
    name: "lang-dev-forms-fields",
    path: "/:lang?/dev/forms/fields",
    meta: fieldsDYVaXJT4S0Meta || {},
    component: () => import("/build/pages/[[lang]]/dev/forms/fields.vue").then(m => m.default || m)
  },
  {
    name: "lang-dev-forms",
    path: "/:lang?/dev/forms",
    meta: indexpiAgDQeGR9Meta || {},
    component: () => import("/build/pages/[[lang]]/dev/forms/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-dev-load-script",
    path: "/:lang?/dev/load-script",
    meta: load_45scriptdFCBbK6EaHMeta || {},
    component: () => import("/build/pages/[[lang]]/dev/load-script.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/:lang?/login",
    meta: indexGeKLNkvPfuMeta || {},
    component: () => import("/build/pages/[[lang]]/login/index.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/:lang?/logout",
    component: () => import("/build/pages/[[lang]]/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses",
    path: "/:lang?/my-account/addresses",
    meta: addressesakWgKQ3uVLMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-buy-again",
    path: "/:lang?/my-account/buy-again",
    meta: buy_45again7GcZRt0jPhMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/buy-again.vue").then(m => m.default || m)
  },
  {
    name: "account-data",
    path: "/:lang?/my-account/data",
    meta: dataUZJF7QDi9gMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/data.vue").then(m => m.default || m)
  },
  {
    name: "account-friends",
    path: "/:lang?/my-account/friends",
    component: () => import("/build/pages/[[lang]]/my-account/friends.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/:lang?/my-account",
    meta: indextGSrBiUrcgMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-newsletter",
    path: "/:lang?/my-account/newsletter",
    meta: newsletterIeXWzYxdQsMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "orders-contact",
    path: "/:lang?/my-account/orders/help/:id()",
    meta: _91id_933yUs7BI46dMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/orders/help/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-help-confirmation",
    path: "/:lang?/my-account/orders/help/confirmation",
    meta: confirmationeJ0Qq31HCjMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/orders/help/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-my-account-orders",
    path: "/:lang?/my-account/orders",
    meta: indexAeIJD5NpaAMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "/:lang?/my-account/orders/market",
    meta: index7XiYwTym7oMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/orders/market/index.vue").then(m => m.default || m)
  },
  {
    name: "account-payback",
    path: "/:lang?/my-account/payback",
    meta: paybackdoxb492qlsMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/payback.vue").then(m => m.default || m)
  },
  {
    name: "account-payment",
    path: "/:lang?/my-account/payment",
    meta: paymentGf0KkZqb7mMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/payment.vue").then(m => m.default || m)
  },
  {
    name: "account-pets",
    path: "/:lang?/my-account/pets",
    meta: petsawHZmg9lOxMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/pets.vue").then(m => m.default || m)
  },
  {
    name: "account-recommendations",
    path: "/:lang?/my-account/recommendations",
    meta: recommendations9IoZoMlAHaMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/recommendations.vue").then(m => m.default || m)
  },
  {
    name: "account-saved-posts",
    path: "/:lang?/my-account/saved-posts",
    meta: saved_45postsMc1r1sy4VQMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/saved-posts.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist",
    path: "/:lang?/my-account/wishlist",
    meta: wishlisto5JqIA8tcdMeta || {},
    component: () => import("/build/pages/[[lang]]/my-account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "campaign-unsubscribe",
    path: "/:lang?/newsletter/campaign/unsubscribe/:id()",
    meta: _91id_93AgNhpjm4cnMeta || {},
    component: () => import("/build/pages/[[lang]]/newsletter/campaign/unsubscribe/[id].vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation",
    path: "/:lang?/newsletter/confirmation/:id()",
    component: () => import("/build/pages/[[lang]]/newsletter/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "newsletter-preferences",
    path: "/:lang?/newsletter/edit-preferences/:id?",
    component: () => import("/build/pages/[[lang]]/newsletter/edit-preferences/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "newsletter-action-redirect",
    path: "/:lang?/newsletter/subscription/:action()",
    component: () => import("/build/pages/[[lang]]/newsletter/subscription/[action].vue").then(m => m.default || m)
  },
  {
    name: "newsletter-unsubscribe",
    path: "/:lang?/newsletter/unsubscribe/:id()",
    component: () => import("/build/pages/[[lang]]/newsletter/unsubscribe/[id].vue").then(m => m.default || m)
  },
  {
    name: "product-detail",
    path: "/:lang?/p/:product()",
    component: () => import("/build/pages/[[lang]]/p/[product].vue").then(m => m.default || m)
  },
  {
    name: "password-request",
    path: "/:lang?/password/request",
    component: () => import("/build/pages/[[lang]]/password/request/index.vue").then(m => m.default || m)
  },
  {
    name: "password-request-thank-you",
    path: "/:lang?/password/request/thank-you",
    component: () => import("/build/pages/[[lang]]/password/request/thank-you/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-expired",
    path: "/:lang?/password/reset/expired",
    component: () => import("/build/pages/[[lang]]/password/reset/expired/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-password",
    path: "/:lang?/password/reset/password",
    component: () => import("/build/pages/[[lang]]/password/reset/password/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/:lang?/register",
    meta: indexEZkfQuuGVyMeta || {},
    component: () => import("/build/pages/[[lang]]/register/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/:lang?/search",
    meta: indexslofJdndAGMeta || {},
    component: () => import("/build/pages/[[lang]]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/:lang?/store-editor/dashboard",
    meta: indexrYXIzt6F0pMeta || {},
    component: () => import("/build/pages/[[lang]]/store-editor/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "store-editor-data",
    path: "/:lang?/store-editor/data",
    meta: indexjP1vKzeyeCMeta || {},
    component: () => import("/build/pages/[[lang]]/store-editor/data/index.vue").then(m => m.default || m)
  },
  {
    name: "store-editor-detail",
    path: "/:lang?/store-editor/detail/:id()",
    meta: _91id_93OpbL7uawIMMeta || {},
    component: () => import("/build/pages/[[lang]]/store-editor/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "store-editor-login",
    path: "/:lang?/store-editor",
    meta: indexK4PE37mgBlMeta || {},
    component: () => import("/build/pages/[[lang]]/store-editor/index.vue").then(m => m.default || m)
  },
  {
    name: "store-listing",
    path: "/:lang?/storefinder",
    component: () => import("/build/pages/[[lang]]/storefinder/index.vue").then(m => m.default || m)
  },
  {
    name: "stores",
    path: "/:lang?/stores/:slug()",
    component: () => import("/build/pages/[[lang]]/stores/[slug].vue").then(m => m.default || m)
  },
  {
    name: "subscription-preferences",
    path: "/:lang?/subscription/newsletter/preferences",
    component: () => import("/build/pages/[[lang]]/subscription/newsletter/preferences/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-unsubscribe",
    path: "/:lang?/subscription/newsletter/unsubscribe",
    component: () => import("/build/pages/[[lang]]/subscription/newsletter/unsubscribe/index.vue").then(m => m.default || m)
  }
]