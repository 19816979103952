import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import pwa_client_Eorgdr12jA from "/build/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import _00_log_OaqnD00uN2 from "/build/plugins/00.log.ts";
import _20_error_handler_L8E2KxI1rZ from "/build/plugins/20.error-handler.js";
import _25_lang_handler_52B2exbmXP from "/build/plugins/25.lang-handler.js";
import _31_axios_client_c8wA84nPXl from "/build/plugins/31.axios.client.ts";
import _35_api_rsvecqqS4v from "/build/plugins/35.api.js";
import _41_sentry_client_X8q5fpfHxp from "/build/plugins/41.sentry.client.js";
import __init_UXlltdub1l from "/build/plugins/45.init.ts";
import __page_loading_client_EzGiJz1fKs from "/build/plugins/46.page-loading.client.ts";
import _50_lazy_sizes_client_0mQenNFjra from "/build/plugins/50.lazy-sizes.client.js";
import _55_mboxes_client_L6mc2K41gU from "/build/plugins/55.mboxes.client.ts";
import _60_init_mobile_view_client_lOZ2YX383E from "/build/plugins/60.init-mobile-view.client.js";
import _65_ab_tests_sssqlTLDTc from "/build/plugins/65.ab-tests.js";
import _70_modal_links_client_Uvtz9Dt9TJ from "/build/plugins/70.modal-links.client.ts";
import _75_v_calendar_DyUnQgIAyv from "/build/plugins/75.v-calendar.ts";
import _80_v_sticky_hoJv0vPLIu from "/build/plugins/80.v-sticky.ts";
import _85_vee_validate_XBDMKobg6u from "/build/plugins/85.vee-validate.ts";
import _90_voucher_client_PSrbv7ZZ9i from "/build/plugins/90.voucher.client.js";
import _95_window_functions_client_w21IVPCZes from "/build/plugins/95.window-functions.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  pwa_client_Eorgdr12jA,
  _00_log_OaqnD00uN2,
  _20_error_handler_L8E2KxI1rZ,
  _25_lang_handler_52B2exbmXP,
  _31_axios_client_c8wA84nPXl,
  _35_api_rsvecqqS4v,
  _41_sentry_client_X8q5fpfHxp,
  __init_UXlltdub1l,
  __page_loading_client_EzGiJz1fKs,
  _50_lazy_sizes_client_0mQenNFjra,
  _55_mboxes_client_L6mc2K41gU,
  _60_init_mobile_view_client_lOZ2YX383E,
  _65_ab_tests_sssqlTLDTc,
  _70_modal_links_client_Uvtz9Dt9TJ,
  _75_v_calendar_DyUnQgIAyv,
  _80_v_sticky_hoJv0vPLIu,
  _85_vee_validate_XBDMKobg6u,
  _90_voucher_client_PSrbv7ZZ9i,
  _95_window_functions_client_w21IVPCZes
]